import { render, staticRenderFns } from "./CreateNewContacts.vue?vue&type=template&id=380b09c5&scoped=true"
import script from "./CreateNewContacts.vue?vue&type=script&lang=js"
export * from "./CreateNewContacts.vue?vue&type=script&lang=js"
import style0 from "./CreateNewContacts.vue?vue&type=style&index=0&id=380b09c5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "380b09c5",
  null
  
)

export default component.exports