<template>
  <v-menu v-model="open" transition="slide-y-transition" close-on-click offset-y bottom>
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex create-new-contacts__add-button mx-auto">
        <v-btn tile color="primary" @click="$emit('createContact')">
          <IcoAddContact />
          <template v-if="isButtonTextVisible">
            {{ $t('contacts.create') }}
          </template>
        </v-btn>

        <v-divider vertical color="white" class="create-new-contacts__divider"></v-divider>

        <v-btn icon color="white" v-bind="attrs" v-on="on">
          <v-icon v-if="open"> mdi-chevron-up </v-icon>

          <v-icon v-else> mdi-chevron-down </v-icon>
        </v-btn>
      </div>
    </template>

    <v-list>
      <v-list-item-group color="primary">
        <v-list-item>
          <v-list-item-title @click="$emit('importContacts')">
            <v-icon color="primary">mdi-application-import</v-icon>
            {{ $t('contacts.import') }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import IcoAddContact from '@/views/dashboard/components/icons/IcoAddContact'
export default {
  name: 'CreateNewContacts',
  components: { IcoAddContact },
  data() {
    return {
      open: false,
    }
  },
  props: {
    isToolbarButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isButtonTextVisible() {
      return this.isToolbarButton ? !this.$vuetify.breakpoint.xs && this.isToolbarButton : true
    },
  },
}
</script>

<style lang="scss" scoped>
.create-new-contacts__add-button {
  background-color: #5f23a0;
  border-radius: 8px;
  border: 1px solid #5f23a0;
  overflow: hidden;
  width: fit-content;

  svg {
    margin-right: 0.5rem;
  }
}

.create-new-contacts__divider {
  border-color: #b1bbcb;
}
</style>
